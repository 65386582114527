<template>
  <b-modal
    v-model="onControl"
    title="CANCEL WORK PLAN"
    title-tag="h3"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    scrollable
    no-close-on-backdrop
    :hide-footer="false"
    @hidden="close"
  >
    <b-container>
      <validation-observer ref="form">
        <validation-provider
          v-slot="{ errors }"
          :name="comment"
          rules="required"
        >
          <b-form-group label="COMMENT:" label-for="input-1">
            <b-form-textarea
              v-model="comment"
              placeholder="..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <span v-if="errors[0]" class="text-danger">
              {{ comment }} {{ errors[0] }}</span
            >
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <b-button variant="primary" @click="annulWorkPlan()"> SEND </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  props: {
    idWorkPlan: null,
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      comment: "",
    };
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    async annulWorkPlan() {
      try {
        const result1 = await this.$refs.form.validate();
        if (result1) {
          const result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            this.addPreloader();
            const data = await ClientsOptionsServices.sendAnnulWorkPlan({
              idWorkPlan: this.idWorkPlan,
              session: this.currentUser.user_id,
              comment: this.comment,
            });
            if (data.status === 200) {
              this.removePreloader();
              this.showSuccessSwal();
              this.$emit("reload");
              this.close();
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"paginate":_vm.paginate,"total-rows":_vm.totalRows,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['workplansTable'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"workplansTable",attrs:{"slot":"table","responsive":"","show-empty":"","stacked":"lg","busy":_vm.isBusy,"fields":_vm.showFields,"items":_vm.provider,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2",staticStyle:{"color":"#dfb15a !important"}},[_c('b-spinner',{staticClass:"align-middle mr-1",attrs:{"type":"grow"}}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [_c('router-link',{class:_vm.textLink,attrs:{"to":{
              name: 'specialists-client-dashboard',
              params: { idClient: data.item.account_id },
            }}},[_vm._v(" "+_vm._s(data.value)+" ")]),_c('div',[_vm._v(" "+_vm._s(data.item.account)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('h6',[_c('b-badge',{staticClass:"px-1",attrs:{"variant":data.item.request_status === 'IN PROGRESS' ||
                data.item.request_status === 'ANNUL'
                  ? 'light-danger'
                  : data.item.request_status === 'PENDING WORKPLAN' ||
                    data.item.request_status === 'PENDING ROUND LETTER'
                  ? 'light-warning'
                  : data.item.request_status === 'WAITING FOR APPROVAL'
                  ? 'light-info'
                  : 'light-success'}},[_vm._v(" "+_vm._s(data.item.request_status)+" ")])],1)]}},{key:"cell(completion_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.value))+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.value))+" ")]}},{key:"cell(canceled_by)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.canceled_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.canceled_at))+" ")]}},{key:"cell(comment)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.comment.substring(0, 75) + "...")+" ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.b.bottom",value:(data.item.comment),expression:"data.item.comment",modifiers:{"hover":true,"b":true,"bottom":true}}],attrs:{"icon":"InfoIcon","size":"15"}})]}},{key:"cell(report)",fn:function(data){return [_c('router-link',{attrs:{"to":{
              name: 'report-lead-specialists',
              params: {
                idlead: data.item.lead_id,
                idfile: data.item.score_id,
                accountId: data.item.account_id,
              },
            }}},[_c('FeatherIcon',{staticClass:"text-success",attrs:{"icon":"FileTextIcon","size":"20"}})],1)]}},{key:"cell(actions)",fn:function(data){return [(data.item.workplan_id)?_c('i',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openWorkPlanModal(data.item)}}},[_c('FeatherIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('WORKPLAN'),expression:"'WORKPLAN'",modifiers:{"hover":true}}],staticClass:"text-success",attrs:{"icon":"ClipboardIcon","size":"20"}})],1):_c('i',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openWorkPlanModal(data.item)}}},[_c('FeatherIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('WORKPLAN'),expression:"'WORKPLAN'",modifiers:{"hover":true}}],staticClass:"text-light",attrs:{"icon":"ClipboardIcon","size":"20"}})],1),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('ROUNDS'),expression:"'ROUNDS'",modifiers:{"hover":true}}],staticClass:"text-center cursor-pointer",on:{"click":function($event){return _vm.openRoundModal(data.item)}}},[(data.item.total_rounds_ncr)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(data.item.total_rounds_ncr)+" ")]):_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("0")])],1)]}},{key:"cell(complete)",fn:function(data){return [_c('b-button',{attrs:{"variant":"info","disabled":data.item.request_status === 'WAITING FOR APPROVAL'
                ? false
                : true,"size":"sm"},on:{"click":function($event){return _vm.completeRequest(data.item)}}},[_vm._v(" Complete ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('ANNUL'),expression:"'ANNUL'",modifiers:{"hover":true}}],staticClass:"cursor-pointer ml-1 text-danger",attrs:{"icon":"SlashIcon","size":"20","v-if":data.item.request_status === 'IN PROGRESS'},on:{"click":function($event){return _vm.openModalAnnulWorkPlan(data.item)}}})]}}])})]},proxy:true}])}),(_vm.workPlanController)?_c('modal-add-work-plan',{attrs:{"id-work-plan":_vm.idWorkPlan,"client_id":_vm.workPlanClient.account_id,"client-name":_vm.workPlanClient.client_name,"account":_vm.workPlanClient.account,"hasRounds":_vm.workPlanClient.hasRoundsCreated},on:{"reload":_vm.refresh,"close":_vm.closeWorkPlanModal}}):_vm._e(),(_vm.roundModalController)?_c('add-round-modal',{attrs:{"data-round":_vm.itemClient,"validate-modal":0},on:{"refresh":_vm.refresh,"close":_vm.closeRoundModal}}):_vm._e(),(_vm.openAnnulWorkPlanModal)?_c('modal-annul-work-plan',{attrs:{"idWorkPlan":this.idWorkPlan},on:{"reload":_vm.refresh,"close":_vm.closeAnnulWorkPlanModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
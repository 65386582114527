<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :paginate="paginate"
      :total-rows="totalRows"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['workplansTable'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          responsive
          show-empty
          ref="workplansTable"
          stacked="lg"
          :busy.sync="isBusy"
          :fields="showFields"
          :items="provider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center my-2" style="color: #dfb15a !important">
              <b-spinner class="align-middle mr-1" type="grow" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <router-link
              :to="{
                name: 'specialists-client-dashboard',
                params: { idClient: data.item.account_id },
              }"
              :class="textLink"
            >
              {{ data.value }}
            </router-link>
            <div>
              {{ data.item.account }}
            </div>
          </template>
          <template #cell(status)="data">
            <h6>
              <b-badge
                :variant="
                  data.item.request_status === 'IN PROGRESS' ||
                  data.item.request_status === 'ANNUL'
                    ? 'light-danger'
                    : data.item.request_status === 'PENDING WORKPLAN' ||
                      data.item.request_status === 'PENDING ROUND LETTER'
                    ? 'light-warning'
                    : data.item.request_status === 'WAITING FOR APPROVAL'
                    ? 'light-info'
                    : 'light-success'
                "
                class="px-1"
              >
                {{ data.item.request_status }}
              </b-badge>
            </h6>
          </template>
          <template #cell(completion_date)="data">
            {{ data.value | myGlobal }}
          </template>
          <template #cell(created_at)="data">
            {{ data.value | myGlobal }}
          </template>
          <template #cell(canceled_by)="data">
            {{ data.item.canceled_by }} <br />
            {{ data.item.canceled_at | myGlobal }}
          </template>
          <template #cell(comment)="data">
            <span>
              {{ data.item.comment.substring(0, 75) + "..." }}
            </span>
            <feather-icon
              icon="InfoIcon"
              size="15"
              v-b-tooltip.hover.b.bottom="data.item.comment"
            />
          </template>
          <template #cell(report)="data">
            <router-link
              :to="{
                name: 'report-lead-specialists',
                params: {
                  idlead: data.item.lead_id,
                  idfile: data.item.score_id,
                  accountId: data.item.account_id,
                },
              }"
            >
              <FeatherIcon icon="FileTextIcon" size="20" class="text-success" />
            </router-link>
          </template>
          <template #cell(actions)="data">
            <i
              v-if="data.item.workplan_id"
              style="cursor: pointer"
              @click="openWorkPlanModal(data.item)"
            >
              <FeatherIcon
                icon="ClipboardIcon"
                size="20"
                v-b-tooltip.hover="'WORKPLAN'"
                class="text-success"
              />
            </i>
            <i
              v-else
              style="cursor: pointer"
              @click="openWorkPlanModal(data.item)"
            >
              <FeatherIcon
                icon="ClipboardIcon"
                size="20"
                v-b-tooltip.hover="'WORKPLAN'"
                class="text-light"
              />
            </i>
            <i
              class="text-center cursor-pointer"
              v-b-tooltip.hover="'ROUNDS'"
              @click="openRoundModal(data.item)"
            >
              <b-badge v-if="data.item.total_rounds_ncr" variant="primary">
                {{ data.item.total_rounds_ncr }}
              </b-badge>
              <b-badge v-else variant="primary">0</b-badge>
            </i>
          </template>
          <template #cell(complete)="data">
            <b-button
              variant="info"
              :disabled="
                data.item.request_status === 'WAITING FOR APPROVAL'
                  ? false
                  : true
              "
              size="sm"
              @click="completeRequest(data.item)"
            >
              Complete
            </b-button>

            <feather-icon
              icon="SlashIcon"
              size="20"
              v-b-tooltip.hover="'ANNUL'"
              class="cursor-pointer ml-1 text-danger"
              @click="openModalAnnulWorkPlan(data.item)"
              :v-if="data.item.request_status === 'IN PROGRESS'"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-add-work-plan
      v-if="workPlanController"
      :id-work-plan="idWorkPlan"
      :client_id="workPlanClient.account_id"
      :client-name="workPlanClient.client_name"
      :account="workPlanClient.account"
      :hasRounds="workPlanClient.hasRoundsCreated"
      @reload="refresh"
      @close="closeWorkPlanModal"
    />
    <add-round-modal
      v-if="roundModalController"
      :data-round="itemClient"
      :validate-modal="0"
      @refresh="refresh"
      @close="closeRoundModal"
    />

    <modal-annul-work-plan
      v-if="openAnnulWorkPlanModal"
      :idWorkPlan="this.idWorkPlan"
      @reload="refresh"
      @close="closeAnnulWorkPlanModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import workPlanRequestService from "@/views/ce-digital/sub-modules/connection/views/work-plans/work-plans.service.js";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";
import ModalAnnulWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAnnulWorkPlan.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal";

export default {
  components: {
    ModalAddWorkPlan,
    AddRoundModal,
    ModalAnnulWorkPlan,
  },
  data: () => ({
    filters: [
      {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
        type: "select",
        label: "Status",
        margin: true,
        showLabel: true,
        options: [
          { value: 0, label: "Pending" },
          { value: 1, label: "Completed" },
        ],
        model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
        md: 2,
        visible: true,
      },
    ],
    filterPrincipal: {
      type: "input",
      inputType: "text",
      placeholder: "Client Name...",
      model: "",
    },
    totalRows: 0,
    startPage: 0,
    toPage: 0,
    perPage: 10,
    paginate: {
      perPage: 10,
      currentPage: 1,
    },
    fields: [
      {
        key: "client_name",
      },
      {
        key: "agent_name",
        label: "Agent",
      },
      {
        key: "specialist_name",
        label: "Specialist",
      },
      {
        key: "status",
      },
      {
        key: "completion_date",
        label: "Completed Date",
      },
      {
        key: "created_at",
        label: "Requested Date",
      },
      {
        key: "comment",
        label: "Comment",
        thStyle: { width: "350px" },
      },
      {
        key: "canceled_by",
        label: "Canceled By",
      },
      {
        key: "report",
        label: "CR",
      },
      {
        key: "actions",
        class: "text-center",
      },
      {
        key: "complete",
        class: "text-center",
      },
    ],
    workPlanController: false,
    roundModalController: false,
    workPlanClient: {
      account_id: null,
      client_name: null,
      account: null,
    },
    itemClient: {},
    idWorkPlan: null,
    idNcr: null,
    isBusy: false,
    openAnnulWorkPlanModal: false,
  }),

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    roleId() {
      return this.currentUser.role_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    completed() {
      return this.$route.meta.completed;
    },
    showFields() {
      if (this.completed) {
        if (this.completed == 1) {
          return this.fields.filter(
            (field) =>
              field.key !== "complete" &&
              field.key !== "comment" &&
              field.key !== "canceled_by"
          );
        } else {
          return this.fields.filter(
            (field) =>
              field.key !== "complete" &&
              field.key !== "actions" &&
              field.key !== "report" &&
              field.key !== "completion_date"
          );
        }
      } else {
        return this.fields.filter(
          (field) =>
            field.key !== "comment" &&
            field.key !== "completion_date" &&
            field.key !== "canceled_by"
        );
      }
    },
  },
  mounted() {
    this.filters[2].model = this.moduleId != 11;
  },
  methods: {
    async provider() {
      let body = {
        n_page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        agent_id: this.currentUser.user_id,
        query: this.filterPrincipal.model,
        from_date: this.filters[0].model,
        to_date: this.filters[1].model,
        status: this.completed,
        role: this.roleId,
        module_id: this.moduleId,
      };

      let data = await workPlanRequestService.getWorkPlanRequests(body);

      this.totalRows = data.total;
      this.startPage = data.from;
      this.toPage = data.to;
      this.paginate = {
        perPage: data.per_page,
        currentPage: data.current_page,
      };
      return data.data;
    },
    refresh() {
      this.$refs.workplansTable.refresh();
    },

    openWorkPlanModal(item) {
      this.workPlanClient = item;
      this.idWorkPlan = item.workplan_id;
      this.workPlanClient.hasRoundsCreated = item.total_rounds_ncr != null;
      this.workPlanController = true;
    },
    openRoundModal(item) {
      this.itemClient = {
        id: item.ncr_id,
        account: item.account,
        idAccount: item.account_id,
        clientName: item.client_name,
        clientData: {
          dob: item.dob,
          address: item.address,
          ssn: item.ssn,
          origin_country: item.origin_country,
        },
      };
      this.itemClient.hasWorkPlanCreated = item.workplan_id != null;
      this.roundModalController = true;
    },

    closeWorkPlanModal() {
      this.workPlanController = false;
    },
    closeRoundModal() {
      this.roundModalController = false;
    },

    async completeRequest(item) {
      const swal = await this.showConfirmSwal();
      if (!swal.value) return;
      const params = {
        request_id: item.request_id,
        action_type: "COMPLETED",
        current_status: item.request_status,
      };
      await workPlanRequestService.completeWorkPlanRequest(params);
      this.$refs.workplansTable.refresh();
    },

    openModalAnnulWorkPlan(item) {
      this.idWorkPlan = item.request_id;
      this.openAnnulWorkPlanModal = true;
    },
    closeAnnulWorkPlanModal() {
      this.openAnnulWorkPlanModal = false;
    },
  },
};
</script>
